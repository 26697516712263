import { PortableText } from "@portabletext/react";
import { FAQ } from "./FAQ";
import { ContentImageComponent } from "./ContentImage";
import { ExternalProductLinkHandler } from "@cakemembers/components-core";

const components = {
  types: {
    image: ContentImageComponent,
    faq: FAQ,
  },
  marks: {
    link: CustomLinkComponent,
  },
};

export function RenderContent({
  blocks,
  className,
}: {
  blocks: any[] | null | undefined;
  className?: string;
}) {
  return (
    <div className={className}>
      <PortableText value={blocks || []} components={components} />
    </div>
  );
}

// Custom link renderer
function CustomLinkComponent({ value, children }: any) {
  return (
    <ExternalProductLinkHandler targetUrl={value.href}>
      {children}
    </ExternalProductLinkHandler>
  );
}
