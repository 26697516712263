"use client";
import InfiniteScroll from "react-infinite-scroller";

import { Spinner } from "@cakemembers/components-core";
import type { ApiListResponse } from "@cakemembers/types";
import { FeedItem } from "./FeedItem";
import { useState } from "react";
import { FeedItem as FeedItemType } from "@cakemembers/types";

export function Feed({
  initialData,
  loadMoreAction,
}: {
  initialData: ApiListResponse<FeedItemType>;
  loadMoreAction(cursor?: string): Promise<ApiListResponse<FeedItemType>>;
}) {
  const [state, setState] = useState<{
    items: FeedItemType[];
    cursor: string | undefined;
  }>({
    items: initialData.data,
    cursor: initialData.responseMetadata.nextCursor,
  });
  const [fetching, setFetching] = useState(false);

  async function fetchItems(page: any) {
    console.log("load more data", page, state, fetching);
    if (fetching) {
      return;
    }
    setFetching(true);

    try {
      const result = await loadMoreAction(state.cursor);
      setState((prev) => ({
        items: [...prev.items, ...result.data],
        cursor: result.responseMetadata.nextCursor,
      }));
    } finally {
      setFetching(false);
    }
  }

  return (
    <InfiniteScroll
      className="flex flex-col gap-[24px]"
      pageStart={0}
      initialLoad={false}
      loadMore={fetchItems}
      hasMore={Boolean(state.cursor)}
      loader={<Spinner key={0} />}
    >
      {state.items.map((item) => (
        <FeedItem key={item._id} item={item} />
      ))}
    </InfiniteScroll>
  );
}
