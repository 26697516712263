"use client";

import {
  FormState,
  PrimaryButton,
  SecondaryButton,
  InputProps,
  Heading4,
  Text,
} from "@cakemembers/components-core";
import type { Profile } from "@cakemembers/types";
import React, { useMemo, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";

export function AvatarPickerClient({
  profile,
  action,
}: {
  profile: Profile;
  action(input: FormData): Promise<FormState>;
}) {
  const [loading, setLoading] = useState(false);

  const initialImage = useMemo(() => {
    if (!profile.avatarFull) return undefined;

    const buff = Buffer.from(profile.avatarFull, "base64");
    return new File([buff], "avatar.webp", { type: "image/webp" });
  }, [profile]);
  const [image, setImage] = useState<File | undefined>(initialImage);
  const editor = useRef<AvatarEditor>(null);
  const [scale, setScale] = useState(1.0);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop(dropped) {
      setImage(dropped[0]);
    },
  });

  function handleSubmit() {
    if (editor.current) {
      editor.current.getImageScaledToCanvas().toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("avatar", blob as Blob);

        try {
          setLoading(true);
          await action(formData);
        } finally {
          setLoading(false);
        }
      });
    }
  }

  function removeImage() {
    setImage(undefined);
  }

  return (
    <div>
      <div {...getRootProps()}>
        {image ? (
          <div className="flex flex-col items-center gap-2">
            <AvatarEditor
              ref={editor}
              className="w-64 h-64"
              image={image}
              borderRadius={428}
              scale={scale}
            />
            <RangeInput
              label="Scale"
              onChange={(e) => setScale(parseFloat(e.target.value) / 100)}
              defaultValue={100}
              min={100}
              max={250}
            />
            <div className="flex flex-row gap-2">
              <PrimaryButton loading={loading} onClick={handleSubmit}>
                Set Profile Photo
              </PrimaryButton>
              <SecondaryButton disabled={loading} onClick={removeImage}>
                Choose New Photo
              </SecondaryButton>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="116"
              viewBox="0 0 116 116"
              fill="none"
              className="rounded-full p-4 border-2 border-dashed cursor-pointer"
              onClick={open}
            >
              <path
                d="M65.0504 19.6248C63.0748 16.3849 59.5585 14.4048 55.7614 14.4048H41.8228C38.0257 14.4048 34.5094 16.3849 32.5338 19.6248L30.2228 23.4219H22.864C16.8601 23.4219 11.989 28.293 11.989 34.2969V68.4763C11.989 74.4803 16.8601 79.3513 22.864 79.3513H42.3799C42.9825 79.3559 43.481 78.8755 43.4991 78.2729C43.5716 76.193 43.8299 74.1268 44.2785 72.0968C44.4008 71.4896 44.0292 70.8915 43.4311 70.7374C36.4485 68.8705 30.9797 63.4375 29.0575 56.4683C27.1409 49.4992 29.0575 42.0367 34.1009 36.8573C39.1397 31.6781 46.5477 29.5574 53.5668 31.2838C60.5858 33.0148 66.1633 38.3344 68.2211 45.263C68.3978 45.8566 69.0096 46.2146 69.6122 46.0696C74.4108 44.8824 79.4087 44.7284 84.2758 45.6164C84.602 45.6799 84.9373 45.5938 85.1956 45.3854C85.4539 45.1724 85.6034 44.8597 85.6079 44.5289V34.2974C85.6079 28.2935 80.7369 23.4224 74.7329 23.4224H67.3606L65.0504 19.6248Z"
                fill="#A2988E"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.5952 55.2809C77.8966 52.843 69.5002 54.2115 62.9752 58.9603C56.4457 63.7136 52.5669 71.2806 52.5171 79.3554V79.5366C52.5216 85.8306 54.8552 91.9023 59.0783 96.5701C63.2969 101.242 69.1014 104.183 75.3635 104.822C81.6257 105.46 87.902 103.752 92.977 100.032C98.052 96.3074 101.568 90.8335 102.841 84.6713C104.115 78.5043 103.054 72.0876 99.869 66.66C96.6835 61.2315 91.5995 57.1749 85.5952 55.2809ZM76.6007 95.4552C74.7972 95.4552 73.3382 93.9961 73.3382 92.1927V84.1452H65.2952C63.4917 84.1452 62.0327 82.6861 62.0327 80.8827V78.1638C62.0327 76.3604 63.4917 74.9013 65.2952 74.9013H73.3382V66.8765C73.3382 65.073 74.7972 63.614 76.6007 63.614H79.3195C81.1229 63.614 82.582 65.073 82.582 66.8765V74.9194H90.625C92.4284 74.9194 93.8875 76.3785 93.8875 78.1819V80.9008C93.8875 82.7042 92.4284 84.1633 90.625 84.1633H82.5639V92.2062C82.5639 94.0097 81.1048 95.4687 79.3014 95.4687L76.6007 95.4552Z"
                fill="#A2988E"
              />
              <path
                d="M59.3594 50.4691C59.7083 50.2562 59.8986 49.8574 59.8488 49.4496C59.237 45.1857 56.2554 41.6377 52.1592 40.3056C48.063 38.9734 43.568 40.0835 40.5637 43.1694C37.5595 46.2552 36.5717 50.7819 38.0172 54.8413C39.4582 58.8968 43.0877 61.7832 47.3652 62.2771C47.773 62.3134 48.1672 62.114 48.3711 61.756C51.1216 57.2021 54.8779 53.3375 59.3594 50.4691Z"
                fill="#A2988E"
              />
            </svg>
            <Heading4>Select Profile Photo</Heading4>
            <Text>
              Select a photo to use for your CAKE profile from your camera roll
              or hard drive.
            </Text>
            {/* <img src={defaultAvatar} className="w-64 h-64 cursor-pointer" /> */}
            <div className="mt-[100px]">
              <SecondaryButton onClick={open}>
                Add Profile Photo
              </SecondaryButton>
            </div>
          </div>
        )}

        <input {...getInputProps()} />
      </div>
    </div>
  );
}

export default AvatarPickerClient;

export const RangeInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { label, min, max, ...rest } = props;
    return (
      <div className="w-full my-5 mb-0">
        <label className="block tracking-wide text-xs font-bold mb-2 flex flex-row items-center">
          {props.label}
        </label>
        <input
          type="range"
          className="range range-xs range-primary accent-primary w-full"
          ref={ref}
          min={min}
          max={max}
          {...rest}
        />
      </div>
    );
  }
);
