"use client";
import { ChevronDownIcon, Heading3 } from "@cakemembers/components-core";
import { RenderContent } from "./RenderContent";
import { useState } from "react";

export function FAQItem({
  idx,
  question,
  answer,
}: {
  idx: number;
  question: string;
  answer: any[];
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <div
        onClick={() => setExpanded((c) => !c)}
        className="group py-3 mx-2 md:py-5 md:px-4 flex flex-row items-center border-b border-black/10 cursor-pointer"
      >
        <Heading3 className="group-aria-expanded:text-secondary grow">
          {question}
        </Heading3>
        <ChevronDownIcon className="ml-auto group-aria-expanded:rotate-180" />
      </div>
      {expanded && (
        <div className="border-b border-black/10 p-3 md:p-6">
          <RenderContent blocks={answer} />
        </div>
      )}
    </div>
  );
}
