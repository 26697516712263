import { FAQItem } from "./FAQItem";

export function FAQ({
  value,
  isInline,
}: {
  value: {
    questions: Array<{
      question: string;
      answer: any;
    }>;
  };
  isInline: boolean;
}) {
  return (
    <div>
      {value.questions.map((qa, idx) => (
        <FAQItem
          idx={idx}
          key={idx}
          question={qa.question}
          answer={qa.answer}
        />
      ))}
    </div>
  );
}
