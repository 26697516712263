import type { Profile } from "@cakemembers/types";
import Link from "next/link";
import { Avatar } from "../Avatar";
import { Text } from "@cakemembers/components-core";

export function AvatarAndName({
  profile,
  action,
  linkToProfile,
  avatarSize = "md",
}: {
  profile: Profile;
  action?: React.ReactNode;
  linkToProfile?: boolean;
  avatarSize?: "md" | "lg";
}) {
  const inside = (
    <div className="w-full p-4 flex flex-row items-center gap-2 ">
      <Avatar profile={profile} size={avatarSize} />
      <div className="grow flex flex-col">
        {profile.displayName ? (
          <>
            <Text size="lg" className="text-[#7B6E70]">
              {profile.displayName}
            </Text>
            <Text size="sm" face="sans">
              {profile.username}
            </Text>
          </>
        ) : (
          <Text size="lg">{profile.username}</Text>
        )}
      </div>
      {action}
    </div>
  );

  if (linkToProfile) {
    return (
      <Link className="w-full" href={`/${profile.username}`}>
        {inside}
      </Link>
    );
  }

  return inside;
}
