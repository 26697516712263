import { ProfileConnection } from "@cakemembers/types";
import Link from "next/link";
import { Avatar } from "../Avatar";
import { Text } from "@cakemembers/components-core";
import dayjs from "dayjs";
import classNames from "classnames";

export function ItemHeader({
  creator,
  createdAt,
}: {
  creator: ProfileConnection;
  createdAt: string;
}) {
  return (
    <Link
      href={`/${creator.profile.username}`}
      className="flex flex-row items-center gap-2"
    >
      <Avatar profile={creator.profile} />
      <div>
        <div
          className={classNames({
            uppercase:
              creator.profile.type === "brand" ||
              creator.profile.username === "cake",
          })}
        >
          {creator.profile.displayName || creator.profile.username}
        </div>
        <div>
          <Text face="sans" className="text-[#AFADAA]" size="sm">
            {dayjs(createdAt).format("MMM D, YYYY")}
          </Text>
        </div>
      </div>
    </Link>
  );
}
