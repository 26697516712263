"use client";
import { useDebouncedCallback } from "use-debounce";

import {
  CancelIcon,
  ChevronRightIcon,
  CircleButton,
  GhostButton,
  SearchIcon,
  Spinner,
  Text,
  TextInput,
} from "@cakemembers/components-core";

import { findMembersAction } from "./actions";
import { useRef, useState } from "react";
import { Profile, Connection } from "@cakemembers/types";
import { AvatarAndName } from "./partials/AvatarAndName";

type Results = { profile: Profile; connection: Connection }[];

export function FindMembers() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [results, setResults] = useState<Results | undefined>();
  const [loading, setLoading] = useState(false);
  const handleChange = useDebouncedCallback(async (e) => {
    console.log("change", e.target.value);

    if (e.target.value === "") {
      setResults(undefined);
      return;
    }

    if (inputRef.current?.validity.valid === false) {
      return;
    }

    setLoading(true);
    const result = await findMembersAction(e.target.value);
    setResults(result);
    setLoading(false);
  }, 333);

  function clear() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setResults(undefined);
  }

  let rightIcon: React.ReactNode = undefined;
  if (loading) {
    rightIcon = <CircleButton size="sm" icon={<Spinner />} disabled />;
  } else if (inputRef.current && inputRef.current?.value !== "") {
    rightIcon = (
      <CircleButton size="sm" icon={<CancelIcon />} onClick={clear} />
    );
  }

  return (
    <div>
      <TextInput
        ref={inputRef}
        name="search"
        placeholder="Member Search"
        icon={<SearchIcon />}
        rightIcon={rightIcon}
        onChange={handleChange}
        minLength={4}
        required
      />
      {results && <Results results={results} />}
    </div>
  );
}

function Results({ results }: { results: Results }) {
  return (
    <div className="z-50 relative w-full">
      <div className="w-full absolute top-0 left-0 shadow-md rounded-b-lg bg-white">
        {results.length === 0 && (
          <div className="p-4 bg-white border-t ">
            <Text>No results</Text>
          </div>
        )}
        {results.map((result) => (
          <div key={result.profile.id} className="border-t last:border-b">
            <AvatarAndName
              profile={result.profile}
              linkToProfile
              action={
                <GhostButton
                  size="sm"
                  icon={<ChevronRightIcon className="fill-gray-300" />}
                ></GhostButton>
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
}
