import { Heading3, Text } from "@cakemembers/components-core";
import { FeedItem, FeedItemSlice } from "@cakemembers/types";
import { SanityImageServer } from "@cakemembers/components-cake";
import { ItemHeader } from "./ItemHeader";
import { ShopOverlay } from "./ShopOverlay";

export function RenderSlice({ item }: { item: FeedItemSlice }) {
  const aspectRatio = item.image.asset.metadata?.dimensions?.aspectRatio || 1;
  return (
    <div className="flex flex-col gap-2">
      <ItemHeader creator={item.creator} createdAt={item._createdAt} />
      <div className="relative">
        <SanityImageServer
          image={item.image}
          alt={`Image for story: ${item.title}`}
          height={aspectRatio * 500}
          width={aspectRatio * 500}
          className="w-full h-auto"
        />
        {item.shopLink && <ShopOverlay itemType={item.postType} shopLink={item.shopLink} />}
      </div>
      <div className="flex flex-col">
        <Heading3>{item.title}</Heading3>
        <Text size="lg">{item.excerpt} </Text>
      </div>
    </div>
  );
}
