import { PostCTA } from "./PostCTA";

export function ShopOverlay({
  shopLink,
  itemType,
}: {
  shopLink: string;
  itemType?: string;
}) {
  return (
    <PostCTA
      link={shopLink}
      target="_blank"
      cta="Shop Now"
      itemType={itemType}
    />
  );
}
