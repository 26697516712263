"use client";
import { Button, PrimaryButton } from "@cakemembers/components-core";
import { useToast } from "@cakemembers/components-motion";
import { isWebShareAvailable } from "@cakemembers/utils";

export function ShareButton({
  url,
  text,
  title,
}: {
  url: string;
  text: string | (() => string);
  title?: string;
}) {
  const { addToast } = useToast();
  async function handleClick() {
    if (!isWebShareAvailable()) {
      return;
    }
    const shareText = typeof text === "string" ? text : text();
    await navigator.share({
      text: shareText,
      url,
    });
    addToast("Shared successfully!");
  }
  return <PrimaryButton onClick={handleClick}>Share Invitation</PrimaryButton>;
}
