import {
  getSanityRefId,
  getImageDimensions,
  SanityImageServer,
  SanityImageType,
} from "@cakemembers/components-cake";

export const ContentImageComponent = ({
  value,
  isInline,
}: {
  value: SanityImageType;
  isInline: boolean;
}) => {
  const { width, height } = getImageDimensions(getSanityRefId(value));

  return (
    <SanityImageServer image={value} alt="" width={width} height={height} />
  );
};
