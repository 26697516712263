"use client";
import {
  Checkbox,
  CopyIcon,
  EmailIcon,
  Heading4,
  Paragraph,
  PrimaryButton,
  Text,
  TextArea,
  TextInput,
} from "@cakemembers/components-core";
import {
  CopyButton,
  MotionDiv,
  useToast,
} from "@cakemembers/components-motion";
import { ShareButton } from "./ShareButton";
import { useMemo, useRef, useState } from "react";
import { redirect } from "next/navigation";
import { isWebShareAvailable } from "@cakemembers/utils";
import { getAccessInfo, getInvitationLink } from "../utils";

export function ShareScreen({
  inviteCode,
  sponsorName,
  recipientName,
  emailInviteAction,
}: {
  inviteCode: string;
  sponsorName: string;
  recipientName: string;
  emailInviteAction(formData: FormData): Promise<void>;
}) {
  const { addToast } = useToast();
  const ref = useRef<HTMLTextAreaElement>(null);
  const [emailChecked, setEmailChecked] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);
  const canShare = useMemo(isWebShareAvailable, []);

  const defaultMessage =
    "You're invited to join CAKE, a private shopping community. Members receive VIP access to coveted brands, thousands of dollars to shop their current collections, and a whole world of special perks and invitations. You're going to love it.";
  const link = getInvitationLink(inviteCode, sponsorName, recipientName);
  const accessInfo = getAccessInfo(inviteCode, sponsorName, recipientName);

  function copyText(includeLink = true) {
    const personalMessage: string | undefined = ref.current?.value || undefined;
    if (personalMessage) {
      return `${personalMessage}
${accessInfo}${includeLink ? "\n\n" + link : ""}`;
    }
    return accessInfo;
  }

  async function handleSubmit(formData: FormData) {
    const start = new Date().getUTCMilliseconds();
    console.log("starting email send");
    await emailInviteAction(formData);
    const end = new Date().getUTCMilliseconds();
    console.log("email sent", end - start);
    addToast("Email sent successfully!");
    redirect("/account/invites");
  }

  return (
    <div className="mb-[100px] px-4 flex flex-col gap-2">
      <Paragraph>
        {canShare
          ? "Review and share invitation link via text, email, or direct message."
          : "Review and copy invitation link into a text or email."}
      </Paragraph>
      <form action={handleSubmit}>
        <div className="my-3 w-full flex flex-col gap-2">
          <TextArea
            ref={ref}
            rows={5}
            name="message"
            label="PERSONAL MESSAGE (EDITABLE)"
            defaultValue={defaultMessage}
          />
          {/* <TextArea
            rows={5}
            name="accessInfo"
            label="ACCESS INFO"
            value={accessInfo}
            disabled
          /> */}
        </div>
        <div className="grow" />
        <div>
          {/* <Checkbox
            label="Send invite by email"
            helperText="Have CAKE email my Invite."
            onChange={() => setEmailChecked(!emailChecked)}
            className="my-4"
          /> */}
        </div>
        {emailChecked && (
          <div className="my-4 flex flex-col gap-6">
            <TextInput
              icon={<EmailIcon />}
              name="email"
              label="Email Address"
            />

            <div>
              <PrimaryButton type="submit">Email Invite</PrimaryButton>
            </div>
          </div>
        )}
      </form>

      {!emailChecked && (
        <div className="flex flex-col gap-2">
          {canShare ? (
            <ShareButton url={link} text={() => copyText(false)} />
          ) : (
            <>
              <CopyButton
                text={copyText}
                icon={<CopyIcon />}
                iconPosition="right"
                onClick={() => setCopyClicked(true)}
              >
                Copy Invitation
              </CopyButton>
              {copyClicked && (
                <MotionDiv initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Text className="text-primary/60">
                    Invite Copied. Paste into Text, Email, or other message
                  </Text>
                </MotionDiv>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
