import { Profile } from "@cakemembers/types";
import classNames from "classnames";

import defaultAvatar from "./default-avatar.png";

export type AvatarProps = {
  profile: Pick<Profile, "avatar" | "avatarThumbnail" | "username">;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  linkToProfile?: boolean;
  stroke?: boolean;
};

export function Avatar({ profile, size = "md", stroke = false }: AvatarProps) {
  let src: string | null | undefined = undefined;

  switch (size) {
    case "xs":
      src =
        profile.avatarThumbnail &&
        `data:image/webp;base64, ${profile.avatarThumbnail}`;
      break;
    case "sm":
      src =
        profile.avatarThumbnail &&
        `data:image/webp;base64, ${profile.avatarThumbnail}`;
      break;
    case "md":
      src = profile.avatar && `data:image/webp;base64, ${profile.avatar}`;
      break;
    case "lg":
      src = profile.avatar && `data:image/webp;base64, ${profile.avatar}`;
      break;
    case "xl":
      src = profile.avatar && `data:image/webp;base64, ${profile.avatar}`;
      break;
  }
  if (!src) {
    src = defaultAvatar.src;
  }

  return (
    <div
      className={classNames(
        "rounded-full",
        {
          "w-6 h-6": size === "xs", // 40px
          "w-8 h-8": size === "sm", // 24px
          "w-12 h-12": size === "md", // 48px
          "w-20 h-20": size === "lg", // 80px
          "w-36	 h-36": size === "xl", // 80px
          "border-2 border-[#CFC3B6]": stroke,
        },
        "rounded-full",
        "overflow-hidden",
        "avatar"
      )}
    >
      <img
        src={src || undefined}
        alt={`${profile.username}'s avatar`}
        className={classNames("w-full h-full object-cover object-center")}
      />
    </div>
  );
}
