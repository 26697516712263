import { Badge } from "@cakemembers/components-core";
import { FeedItemFeatured } from "@cakemembers/types";
import { LogoSpace, SanityImageServer } from "@cakemembers/components-cake";
import Link from "next/link";
import { ItemHeader } from "./ItemHeader";

export function RenderFeatured({ item }: { item: FeedItemFeatured }) {
  return (
    <div className="flex flex-col gap-2">
      <ItemHeader creator={item.creator} createdAt={item._createdAt} />
      <Link
        href={`/brands?brand=${item.referencedBrand.slug}`}
        className="w-full aspect-[3/2] relative"
      >
        <SanityImageServer
          image={item.image}
          aspectRatio="landscape"
          alt={`Image for "New to Cake" post for ${item.referencedBrand.name}`}
          height={450}
          width={800}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          className="absolute w-full h-full"
        />
        <div className="p-4 absolute h-full right-0 top-0">
          <Badge>Featured</Badge>
        </div>
        <div className="absolute top-0 w-full h-full p-4">
          <LogoSpace>
            <SanityImageServer
              alt={`${item.referencedBrand.name} Logo`}
              image={item.referencedBrand.logo}
              height={500}
              width={500}
              style={{
                maxHeight: "33%",
                maxWidth: "66%",
                objectFit: "contain",
              }}
              className="invert"
            />
          </LogoSpace>
        </div>
      </Link>
    </div>
  );
}
